import { toast } from 'react-toastify';

export const notificationMsg = (type, msg) => {
  type = type.toLowerCase();
  const uniqueId = `${type}-${Date.now()}`;

  if (type === 'success') toast.success(msg, { toastId: uniqueId });
  else if (type === 'error') toast.error(msg, { toastId: uniqueId });
  else if (type === 'info') toast.info(msg, { toastId: uniqueId });
  else if (type === 'warning') toast.warning(msg, { toastId: uniqueId });
};
