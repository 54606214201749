import { DEVELOPERS } from "../actions/Types";

const initialState = {
    developersTable: true,
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEVELOPERS:
            return {
                ...state,
                developersTable: !state.developersTable,
            };

        default:
            return { ...state };
    }
};
export default homeReducer;

