import { EMPLOYEEINFO, GET_ALL_EMPLOYEES, GET_ALL_SKILLS } from 'actions/Types';
const initialState = {
  // skills: [],
  employees: [],
  showLoader: false,
};

// export const skillsReducer = (state = initialState.skills, action) => {
//   switch (action.type) {
//     case GET_ALL_SKILLS:
//       state = { ...state, ...action.payload };
//       return state;
//     case 'LOADING_ACTION':
//       return {
//         ...state,
//         showLoader: action.payload,
//       };
//     default:
//       return { ...state };
//   }
// };

export const employeesReducer = (state = initialState.employees, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES:
      state = [...action.payload];
      return state;
    default:
      return state;
  }
};

export const employeeInfoReducer = (state = { employeeInfo: {} }, action) => {
  switch (action.type) {
    case EMPLOYEEINFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
