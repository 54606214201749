export const companyDivision = (state, action) => {
  switch (action.type) {
    case 'GET_COMPANY_DIVISION':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const billingStatus = (state, action) => {
  switch (action.type) {
    case 'GET_BILLING_STATUS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const engineeringDivision = (state, action) => {
  switch (action.type) {
    case 'GET_ENNGINEERING_DIVISION':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const seniorityDivision = (state, action) => {
  switch (action.type) {
    case 'GET_SENIORITY_DIVISION':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const offBoardingDetails = (state, action) => {
  switch (action.type) {
    case 'GET_OFFBOARDING_DETAILS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const onBoardingDetails = (state, action) => {
  switch (action.type) {
    case 'GET_ONBOARDING_DETAILS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const headCount = (state, action) => {
  switch (action.type) {
    case 'GET_HEAD_COUNT':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
