const initialState = {
  loansReq: [],
  myLoans: [],
  allLoansHr: [],
  allUnassignedRequests: [],
  allLoansRequestHrHod: [],
  myInstallments: [],
  allInstallments: [],
  allHardwareLoss: [],
  myHardwareRequests: [],
  installmentHistory: [],
  hrHardwareRequests: [],
  myAssetsDetail: [],
};

export const loans = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LOAN_REQUESTS':
      return {
        ...state,
        loansReq: action.payload,
      };
    case 'GET_MY_LOANS':
      return {
        ...state,
        myLoans: action.payload,
      };
    case 'GET_ALL_LOANS_HR':
      return {
        ...state,
        allLoansHr: action.payload,
      };
      case 'GET_UNASSIGNED_REQUESTS':
        return {
          ...state,
          allUnassignedRequests: action.payload,
        };
      case 'GET_LOANS_REQUEST_HR_HOD':
      return {
        ...state,
        allLoansRequestHrHod: action.payload,
      };
    case 'GET_LOAN_INSTALLMENTS':
      return {
        ...state,
        myInstallments: action.payload,
      };
    case 'GET_ALL_LOAN_INSTALLMENTS':
      return {
        ...state,
        allInstallments: action.payload,
      };
    case 'GET_LOAN_INSTALLMENTS_HISTORY':
      return {
        ...state,
        installmentHistory: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    case 'GET_ALL_HARDWARE_LOSS':
      let filteredItems = action.payload.filter((item) => item.finalCost !== null);
      return {
        ...state,
        allHardwareLoss: action.payload,
        hrHardwareRequests: filteredItems,
      };
    case 'GET_MY_HARDWARE_REQUESTS':
      // let filteredTickets = action.payload.filter((item) => item.isHrReviewed);
      return {
        ...state,
        myHardwareRequests: action.payload,
      };
    case 'GET_MY_ASSETS_DETAIL':
      return {
        ...state,
        myAssetsDetail: action.payload,
      };
    default:
      return { ...state };
  }
};
