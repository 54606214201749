const initialState = {
  positionReq: [],
  positionReqRoleBased: [],
};

export const positionOpenings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_REQUEST_POSITION_OPENINGS':
      return {
        ...state,
        positionReq: action.payload,
      };
    case 'GET_ROLE_BASED_REQUEST_POSITION_OPENINGS':
      return {
        ...state,
        positionReqRoleBased: action.payload,
      };
    default:
      return { ...state };
  }
};
