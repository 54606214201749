const initialState = {
    navigation: [],
  };

  const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_NAVIGATION_BAR':
        return {
          ...state,
          navigation: action.payload,
        };
      default:
        return state;
    }
  };

  export default navigationReducer;