import { SET_LAST_ROUTE } from "../actions/Types";

const initialState = {
    lastRoute: ['/', '/']
  };
  
  const routeHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LAST_ROUTE:{
        let routeArr = state.lastRoute;
        routeArr.shift()
        routeArr.push(action.payload)
          return {
            ...state,
            lastRoute: routeArr,
          };
      }
      default:
        return { ...state };
    }
  };
  export default routeHistoryReducer;
  