const initialState = {
  domains: [],
  array: [],
  reports: [],
  showLoader: false,
};

const emailMarketingDomains = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_EMAIL_MARKETING_DOMAINS':
      return {
        ...state,
        domains: action.payload,
        array: action.array,
      };
    default:
      return { ...state };
  }
};
export default emailMarketingDomains;
