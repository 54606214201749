import uniqBy from 'lodash/uniqBy';
import {
  USER_PROFILE,
  REMOVE_PROJECT,
  FETCH_USER_PERMISSIONS,
  GET_SINGLE_EMPLOYEE,
  SET_RESOURCE_FILTER,
  RESET_RESOURCE_FILTERS,
} from 'actions/Types';

const filtersObj = {
  searchByName: '',
  selectedProject: null,
  selectedGender: null,
  selectedAttendanceMode: null,
  selectedWorkingShift: null,
  selectedJobStatus: [],
  selectedAvailability: [],
  searchByExpectedDate: '',
  selectedLocation: [],
  searchBySkills: [],
  selectedDepartment: [],
  selectedSubDepartment: [],
  selectedDesignation: [],
  selectedDesignationLevel: [],
  check: false,
};

const initialState = {
  usersList: [],
  teamsList: [],
  allUsers: [],
  allResources: [],
  totalUsers: [],
  createdUser: {},
  userData: {},
  updatedUser: {},
  userByLink: {},
  auth_status: '',
  userProfile: {},
  userPermissions: {},
  isActive: false,
  SelectedEmployeeProjects: {},
  employeeProjects: [],
  roles: [],
  skillsList: [],
  singleEmployee: [],
  projectManagers: [],
  devops: [],
  selectedRow: [],
  rolesWithPermissions: [],
  selectedRolePermission: [],
  showLoader: false,
  employees: [],
  offBoardEmployees: [],
  allOffBoardEmployees: [],
  archivedUsers: [],
  projectManagers: [],
  employeeLeaves: [],
  singleEmployeeProjects: [],
  singleEmployeeManagers: [],
  employeeLeavesInfo: {},
  employeeInfo: {},
  resourcesLeavesOfManager: [],
  currentUserInfo: {},
  employeeLeavesOfHR: [],
  employeeLeavesCount: [],
  deletedLeaves: [],
  systemDeletedLeaves: [],
  ceoData: {},
  flagObj: false,
  systemLeavesOfHR: [],
  systemLeavesOfPM: [],
  giftLeaves: [],
  deletedGiftLeaves: [],
  filters: filtersObj,
};

const getItems = (array) => {
  let items = array.map((item) => {
    let obj = {};
    for (let key in item) {
      if (key === 'employeeId') obj['id'] = item[key];
      else if (key === 'id') obj['leaveId'] = item[key];
      else obj[key] = item[key];
    }
    return obj;
  });
  return items;
};

const getDeletedLeave = (array, payload) => {
  for (let key in payload) {
    if (key === 'employeeId') array['id'] = payload[key];
    else if (key === 'id') array['leaveId'] = payload[key];
    else array[key] = payload[key];
  }
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EMPLOYEES':
      return {
        ...state,
        employees: action.employees,
      };
    case 'SET_MY_DEPT_OFF_BOARD_EMPLOYEES':
      return {
        ...state,
        offBoardEmployees: action.offBoardEmployees,
      };
    case 'SET_ALL_OFF_BOARD_EMPLOYEES':
      return {
        ...state,
        allOffBoardEmployees: action.allOffBoardEmployees,
      };
    case 'SET_ALL_RESOURCES':
      return {
        ...state,
        allResources: action.payload,
      };
    case 'SET_ARCHIVED_USERS':
      return {
        ...state,
        archivedUsers: action.archivedUsers,
      };
    case 'CLEAR_ARCHIVED_USERS_STATE':
      return {
        ...state,
        archivedUsers: initialState.archivedUsers,
      };
    case 'GET_USERS':
      return {
        ...state,
        totalUsers: action.totalUsers,
        usersList: action.payload,
        employeeProjects: action.employeeProjects,
        roles: action.roles,
        userPermissions: action.userPermissions,
        projectManagers: action.projectManagers,
        devops: action.devops,
      };
    case 'SET_PROJECT_MANAGERS':
      return {
        ...state,
        projectManagers: action.payload,
      };
    case 'GET_ALL_USERS':
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_SINGLE_EMPLOYEE:
      return {
        ...state,
        singleEmployee: action.payload,
      };
    case 'GET_TEAMS':
      return {
        ...state,
        teamsList: action.payload,
      };
    case 'SET_ROLES':
      return {
        ...state,
        roles: action.payload,
      };
    case 'SET_SKILLS':
      return {
        ...state,
        skillsList: action.payload,
        permission: action?.permission,
      };
    case 'ADD_USER':
      return {
        ...state,
        createdUser: action.payload,
      };
    case 'LOGIN':
      return {
        ...state,
        auth_status: action.payload,
      };
    case 'Edit_USER':
      return {
        ...state,
        updatedUser: action.payload,
      };
    case 'GET_USER':
      return {
        ...state,
        userData: action.payload,
      };
    case 'BULK_USERS':
      return {
        ...state,
        isActive: action.payload,
      };
    case 'GET_USER_BY_LINK':
      return {
        ...state,
        userByLink: action.payload,
      };
    case 'USER_FAIL_BY_LINK':
      return {
        ...state,
        userByLink: {},
      };
    case 'USERS_FAIL':
      return {
        ...state,
        usersList: [],
      };
    case 'USER_FAIL':
      return {
        ...state,
        userData: {},
      };
    case 'ALL_USERS_FAIL':
      return {
        ...state,
        allUsers: [],
      };
    case 'GET_ROLES_PERMISSIONS':
      const rolePermissionData = action?.payload?.map((item) => {
        item.permissions = isValidPermisionsJSON(item?.permissions);
        item.permissions = Object.keys(item.permissions)
          ?.filter((key) => key !== 'board')
          ?.reduce((obj, key) => {
            obj[key] = item.permissions[key];
            return obj;
          }, {});
        return item;
      });
      return {
        ...state,
        rolesWithPermissions: rolePermissionData,
      };
    case 'SELECTED_ROLE_PERMISSIONS':
      return {
        ...state,
        selectedRolePermission: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    case 'TEAMS_FAIL':
      return {
        ...state,
        teamsList: [],
      };
    case 'SELECTED_ROW':
      return {
        ...state,
        selectedRow: action.payload.row,
        selectedRowUserPermissions: action.payload.permissions,
      };
    case USER_PROFILE:
      localStorage.setItem('slack_avatar', action.payload?.slack_avatar);
      return { ...state, userProfile: action.payload };
    case REMOVE_PROJECT:
      return {
        ...state,
        SelectedEmployeeProjects: state.usersList
          .find((el) => el.id === action.payload.userId)
          .Projects.filter((el) => el.id !== action.payload.projectId),
      };
    case FETCH_USER_PERMISSIONS:
      window.username = action.payload?.employee?.name || '';
      return {
        ...state,
        userPermissions: action.payload.employee,
      };
    case 'SET_EMPLOYEES_LEAVES':
      return {
        ...state,
        employeeLeaves: action.employees,
      };
    case 'SET_STATE_NULL':
      return {
        ...state,
        singleEmployeeProjects: [],
        singleEmployeeManagers: [],
        employeeLeavesInfo: {},
        employeeInfo: {},
        currentUserInfo: {},
      };
    case 'SET_EMPLOYEE_DATA':
      const singleEmployeeProjects = action.employee.Projects.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      let managersArray = [];
      if (action?.employee?.Projects?.length) {
        let array = [];
        for (let item of action.employee.Projects) {
          if (item?.Employees?.length)
            array.push({
              id: item?.Employees[0]?.EmployeeProject?.employee_id,
              name: item?.Employees[0]?.name,
              slackAvatar: item?.Employees[0]?.slackAvatar,
            });
        }
        const managers = uniqBy([...array], 'id');
        const validation = !!managers.find((item) => item.id === action.employee.id);
        if (validation) managersArray = action.employee?.myDepartment?.HOD ? [action.employee.myDepartment.HOD] : [];
        else managersArray = managers ? managers : [];
      } else managersArray = action.employee?.myDepartment?.HOD ? [action.employee.myDepartment.HOD] : [];

      return {
        ...state,
        singleEmployeeProjects: singleEmployeeProjects,
        singleEmployeeManagers: managersArray,
        employeeLeavesInfo: action?.employee?.EmployeeLeaveCount[action?.employee?.EmployeeLeaveCount?.length - 1],
        employeeInfo: action?.employee,
        currentUserInfo: action.employee,
      };
    case 'SET_CEO_DATA':
      return {
        ...state,
        ceoData: action.employee,
      };
    case 'SET_CREATE_LEAVE':
      let array = state.employeeLeaves;
      return {
        ...state,
        employeeLeaves: [action.leave, ...array],
      };
    case 'SET_UPDATED_LEAVE':
      let leavesArray = state.employeeLeaves;
      let id = leavesArray.findIndex((item) => item.id === action.leave.id);
      Object.assign(leavesArray[id], action.leave);
      return {
        ...state,
        employeeLeaves: leavesArray,
        flagObj: !state.flagObj,
      };
    case 'SET_DELETED_LEAVE':
      let copyLeaves = state.employeeLeavesOfHR;
      let deletedCopyLeaves = state.deletedLeaves;
      let deletedLeave = {};
      getDeletedLeave(deletedLeave, action.leave);
      copyLeaves = copyLeaves.filter((item) => item.leaveId !== action.leave.id);
      return {
        ...state,
        employeeLeavesOfHR: copyLeaves,
        deletedLeaves: [deletedLeave, ...deletedCopyLeaves],
      };
    case 'SET_DELETED_SYSTEM_LEAVE':
      let copySystemLeaves = state.systemLeavesOfHR;
      let deletedCopySystemLeaves = state.systemDeletedLeaves;
      let deletedSystemLeave = {};
      getDeletedLeave(deletedSystemLeave, action.leave);
      copySystemLeaves = copySystemLeaves.filter((item) => item.leaveId !== action.leave.id);
      return {
        ...state,
        systemLeavesOfHR: copySystemLeaves,
        systemDeletedLeaves: [deletedSystemLeave, ...deletedCopySystemLeaves],
      };
    case 'SET_RESOURCES_LEAVES_OF_MANAGER':
      let newArray = [];
      if (action?.leaves?.length)
        for (let item of action.leaves) {
          let obj = {
            leaves: [],
          };
          // obj.id = item.id;
          obj.id = item.employeeId;
          obj.employeeId = item.employeeId;
          obj.name = item.employeeName;
          obj.slack_avatar = item.slackAvatar;
          obj.leaveCount = item.leaveCount;
          obj.leaves.push(item);
          if (!!newArray.find((el) => el.employeeId === item.employeeId)) newArray.find((el) => el.employeeId === item.employeeId).leaves.push(item);
          else newArray.push(obj);
        }
      return {
        ...state,
        resourcesLeavesOfManager: newArray,
      };
    case 'SET_EMPLOYEE_LEAVES_OF_HR':
      return {
        ...state,
        employeeLeavesOfHR: getItems(action.payload),
        employeeLeavesCount: action.countExport,
      };
    case 'SET_DELETED_LEAVES':
      return {
        ...state,
        deletedLeaves: getItems(action.payload),
      };
    case 'SET_SYSTEM_DELETED_LEAVES':
      return {
        ...state,
        systemDeletedLeaves: getItems(action.payload),
      };
    case 'SET_SYSTEM_LEAVES_OF_HR':
      return {
        ...state,
        systemLeavesOfHR: getItems(action.payload),
      };
    case 'SET_SYSTEM_LEAVES_OF_PM':
      let leavesPm = [];
      if (action?.payload?.length)
        for (let item of action.payload) {
          let obj = {
            leaves: [],
          };
          // obj.id = item.id;
          obj.id = item.employeeId;
          obj.employeeId = item.employeeId;
          obj.name = item.employeeName;
          obj.slack_avatar = item.slackAvatar;
          obj.leaveCount = item.leaveCount;
          obj.leaves.push(item);
          if (!!leavesPm.find((el) => el.employeeId === item.employeeId)) leavesPm.find((el) => el.employeeId === item.employeeId).leaves.push(item);
          else leavesPm.push(obj);
        }
      return {
        ...state,
        systemLeavesOfPM: leavesPm,
      };
    case 'SET_GIFT_LEAVES':
      return {
        ...state,
        giftLeaves: getItems(action.payload),
      };
    case 'SET_DELETED_GIFT_LEAVES':
      return {
        ...state,
        deletedGiftLeaves: getItems(action.payload),
      };
    case SET_RESOURCE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]:
            Array.isArray(action.payload.value) && action.payload.value.length > 0
              ? action.payload.value.map((item) => ({
                  ...item,
                  name: action.payload.name,
                }))
              : action.payload.value || [],
        },
      };
    case RESET_RESOURCE_FILTERS:
      return {
        ...state,
        filters: filtersObj,
      };
    default:
      return { ...state };
  }
};

const isValidPermisionsJSON = (data) => {
  try {
    const parsedData = JSON.parse(data);
    return parsedData;
  } catch (error) {
    return {};
  }
};

export const userPermissionReducer = (state = { employee: {}, user: {} }, action) => {
  switch (action.type) {
    case FETCH_USER_PERMISSIONS:
      window.username = action.payload?.employee?.name || '';
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
