import React from 'react';
import io from 'socket.io-client';

export const socket = new io(process.env.REACT_APP_API_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 5,
  transports: ['websocket'],
  upgrade: false,
});

export const SocketContext = React.createContext();
