export const departments = (state, action) => {
    switch (action.type) {
      case 'GET_DEPARTMENTS':
        return {
          ...state,
          data: action.payload,
        };
      case 'LOADING_ACTION':
        return {
          ...state,
          showLoader: action.payload,
        };
      case 'GET_DEPARTMENTS_RESOURCE_COUNT':
          return {
            ...state,
            departmentsResourceCount: action.payload,
          };
      default:
        return { ...state };
    }
  };