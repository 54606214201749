const initialState = {
  upselloppurtunity: [],
  showLoader: false,
};

const upselloppurtunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_UPSELL_OPPURTUNITY':
      return {
        ...state,
        upselloppurtunity: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
export default upselloppurtunityReducer;
