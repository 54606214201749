import {
    id,
    name,
    Department,
    Skills,
    Exp,
    Location,
    projectManager,
    Availability,
    sinceWhen,
    Designation,
    jobStatus,
    Resume,
} from '../../views/Home/list/components/types/index';

const columnModalInitialState = {
    developerTableColumns: false,
    projectsTableColumn: false,
};

export const columnModalReducer = (state = columnModalInitialState, action) => {
    switch (action.type) {
        case 'DEV_COLUMNS':
            return {
                ...state,
                developerTableColumns: true,
            };
        case 'CLOSE_DEV_COLUMNS':
            return {
                ...state,
                developerTableColumns: !state.developerTableColumns
            };
        default:
            return { ...state };
    }
};


const columnInitialState = {
    id: false,
    Name: false,
    Department: false,
    Skills: false,
    Exp: false,
    Location: false,
    projectManager: false,
    Availability: false,
    sinceWhen: false,
    Designation: false,
    jobStatus: false,
    Resume: false,
};

export const columnReducer = (curState = columnInitialState, action) => {
    switch (action.type) {

        case 'Serial.No#':
            return { ...curState, id: action.value };
        case 'Name':
            return {
                ...curState,
                Name: action.value,
            };
        case Department:
            return {
                ...curState,
                Department: action.value,
            };
        case Skills:
            return {
                ...curState,
                Skills: action.value,
            };
        case 'Experience':
            return {
                ...curState,
                Exp: action.value,
            };
        case Location:
            return {
                ...curState,
                Location: action.value,
            };
        case 'Project Manager':
            return {
                ...curState,
                projectManager: action.value,
            };
        case Availability:
            return {
                ...curState,
                Availability: action.value,
            };
        case 'Since When':
            return {
                ...curState,
                sinceWhen: action.value,
            };
        case Designation:
            return {
                ...curState,
                Designation: action.value,
            };
        case 'Job Status':
            return {
                ...curState,
                jobStatus: action.value,
            };
        case Resume:
            return {
                ...curState,
                Resume: action.value,
            };

        default:
            return { ...curState };
    }
};


const initialColumnState = {
    Project_Name: false,
    Division: false,
    tech_stack: false,
    Country: false,
    Status: false,
    Project_Manager: false,
    Engagement: false,
    Billing_Cycle: false,
    Contract: false,
    Pm_Introduced: false,
    Dev_Introduced: false,
    Csm_Introduced: false,
    Sales_Team: false,
    BD_Team: false,
    Communication: false,
    Engineers: false,
};

export const columnMainReducer = (state = initialColumnState, action) => {
    switch (action.type) {

        case 'Project_Name':
            return { ...state, Project_Name: action.value };
        case 'Division':
            return { ...state, Division: action.value };
        case 'Tech_Stack':
            return { ...state, tech_stack: action.value };
        case 'Country':
            return { ...state, Country: action.value };
        case 'Status':
            return { ...state, Status: action.value };
        case 'Project_Manager':
            return { ...state, Project_Manager: action.value };
        case 'Engagement':
            return { ...state, Engagement: action.value };
        case 'Billing_Cycle':
            return { ...state, Billing_Cycle: action.value };
        case 'Contract':
            return { ...state, Contract: action.value };
        case 'Pm_Introduced':
            return { ...state, Pm_Introduced: action.value };
        case 'Dev_Introduced':
            return { ...state, Dev_Introduced: action.value };
        case 'Csm_Name':
            return { ...state, Csm_Name: action.value };
        case 'Csm_Introduced':
            return { ...state, Csm_Introduced: action.value };
        case 'Sales_Team':
            return { ...state, Sales_Team: action.value };
        case 'BD_Team':
            return { ...state, BD_Team: action.value };
        case 'Communication':
            return { ...state, Communication: action.value };
        case 'Engineers':
            return { ...state, Engineers: action.value };

        default:
            return { ...state };
    }
};