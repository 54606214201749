const initialState = {
    providentFundContributions: [],
    providentFundClaims: []
};

const providentFundReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROVIDENT_FUND_CONTRIBUTIONS':
            return {
                ...state,
                providentFundContributions: action.payload,
            };
        case 'GET_PROVIDENT_FUND_CLAIMS':
            return {
                ...state,
                providentFundClaims: action.payload,
            };
        case 'GET_PROVIDENT_INITIATED_REQUESTS':
                return {
                    ...state,
                    providentFundInitiatedRequests: action.payload,
            };
        default:
            return { ...state };
    }
};

export default providentFundReducer;