import { notificationMsg } from '../../utility/Notification';
import dayjs from 'dayjs';

const initialState = {
  claims: [],
  isVerifiedOverTimeClaimForm: false, // handle flag for submit button with validations
  inputFieldName: '',
  errorMsg: '',
  status: true,
  selectedClaimId: 0,
  isResponseUpdate: '',
  claimInfo: [],
  weekAttendaceInFormat: [],
  isLoading: false,
  isBFormRequired: true,
  BFormData: [],
  claimsOT: [],
  getChildClaims: [],
  val: '',
  currentMonth: false,
  claimsDeadline: [],
};

const claimsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLAIMS':
      const filteredArray = action.payload?.EmployeeClaims.filter((item) => item.claimType === 'CHILD_ALLOWANCE');
      return {
        ...state,
        claims: action.payload?.EmployeeClaims,
        BFormData: action.payload?.BFormData,
        getChildClaims: filteredArray,
        currentMonth:
          action.payload?.EmployeeClaims.find((item) => item.claimType === 'CHILD_ALLOWANCE')?.createdAt.slice(0, 7) === dayjs().format('YYYY-MM')
            ? true
            : false,
      };
    case 'GET_MANAGERS':
      return {
        ...state,
        managers: action.payload,
      };
    case 'GET_CLAIM_INFO':
      return {
        ...state,
        claimInfo: action.payload,
        isLoading: false,
      };
    case 'GET_DEADLINE_CLAIMS':
      return {
        ...state,
        claimsDeadline: action.payload,
      };
    case 'HANDLE_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };

    case 'GET_BFORM':
      let BFormData = state.BFormData;
      if (!action.payload.error) {
        BFormData = !!action.payload.data;
      }
      return {
        ...state,
        BFormData,
      };
    case 'HANDLE_RESPONSE_MSGS':
      const { message, success, inputFieldName, claimId, val } = action.payload;

      return {
        ...state,
        errorMsg: message,
        status: success,
        inputFieldName: inputFieldName,
        selectedClaimId: claimId,
        isResponseUpdate: new Date(),
        val: val,
      };

    case 'GET_WEEK_ATTENDANCE':
      return {
        ...state,
        weekAttendaceInFormat: action.payload,
      };
    case 'GET_OT_CLAIMS':
      return {
        ...state,
        claimsOT: action.payload?.EmployeeClaimsOT,
      };
    default:
      return { ...state };
  }
};

export default claimsReducer;
