const initialState = {
  allTickets: [],
  managerTickets: [],
  userTickets: [],
  assignedTickets: [],
  allTicketsEmployees: [],
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_TICKETS':
      return {
        ...state,
        allTickets: action.payload,
      };
    case 'GET_ALL_TICKETS_EMPLOYEES':
      return {
        ...state,
        allTicketsEmployees: action.payload,
      };
    case 'GET_MANAGER_TICKETS':
      return {
        ...state,
        managerTickets: action.payload,
      };

    case 'GET_USER_TICKETS':
      return {
        ...state,
        userTickets: action.payload,
      };
    case 'UPDATE_TICKET_SUCCESS':
      return {
        ...state,
        userTickets: state.userTickets.map(ticket =>
          ticket.id === action.payload.ticketId ? { ...ticket, ...action.payload } : ticket
        ),
      };
    case 'DELETE_USER_TICKETS':
      return {
        ...state,
        userTickets: state.userTickets.filter(ticket => ticket.id !== action.payload.ticketId),
      };

    case 'GET_ASSIGNED_TICKETS':
      return {
        ...state,
        assignedTickets: action.payload,
      };

    default:
      return { ...state };
  }
};

export default ticketsReducer;
