import { ADD_POLICY, DELETE_POLICY, GET_POLICY_HISTORY, GET_POLICY_LIST, GET_SINGLE_POLICY, SET_ACTIVE_POLICY, UPDATE_POLICY } from '../actions/Types';

const initialState = {
  policyList: [],
  singlePolicy: {},
  policyHistory: []
};

export const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POLICY:
      let list = [...state.policyList];
      list.unshift(action.payload);

      return {
        ...state,
        policyList: list,
      };
    case GET_POLICY_LIST:
      return {
        ...state,
        policyList: action.payload,
      };

      case GET_POLICY_HISTORY:
        return {
          ...state,
          policyHistory: action.payload
        }

    case GET_SINGLE_POLICY:
      return {
        ...state,
        singlePolicy: action.payload,
      };
    case DELETE_POLICY:
      return {
        ...state,
        policyList: state.policyList.filter(item => item.id !== action.payload),
      };
    case UPDATE_POLICY:
      const objIndex = state.policyList?.findIndex(item => item.id === action.payload.id);
      if (objIndex > -1) {
        const list = [...state.policyList]
        list[objIndex] = { ...list[objIndex], ...action.payload };
        // list[objIndex] = action.payload;

        return {
          ...state,
          policyList: list,
        };
      } else {
        return {
          ...state
        }
      }

    case SET_ACTIVE_POLICY:
      const listData = state.policyList?.map(item => {
        if (item.name?.value === action.payload?.name?.value) {
          if (item.id === action.payload?.id) item.isActive = action.payload?.isActive;
          else item.isActive = false;
        }
        return item;
      })
      return {
        ...state,
        policyList: [...listData]
      }

    default:
      return { ...state };
  }
};