import { GET_CARDS_INFO, GET_CARD_COMMENTS, VIEW_IMAGE_LIGHTBOX } from 'actions/Types';

const initialState = {
  laneList: { lanes: [], business_analysts: [] },
  comments: [],
};

const initialImagePreviewStates = {
  photoIndex: 0,
  openImageBox: false,
  selectedCardDetails: null,
};

const boardImagesPreviewReducer = (state = initialImagePreviewStates, action) => {
  switch (action.type) {
    case VIEW_IMAGE_LIGHTBOX:
      state = { ...state, ...action.payload };
      return state;
    default:
      return { ...state };
  }
};

const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARDS_INFO:
      state = { ...state, laneList: action.payload };
      return state;
    case GET_CARD_COMMENTS:
      const sortedCommentsArr = action.payload.comments.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
      state = { ...state, comments: sortedCommentsArr };
      return state;
    default:
      return { ...state };
  }
};

export { boardReducer, boardImagesPreviewReducer };
