const initialState = {
    offlineTimeRequests: [],
    myOfflineTimeRequests: [],
    ITPersons:[],
  };

const offlineTimeRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OFFLINE_TIME_REQUESTS':
      return {
        ...state,
        offlineTimeRequests: action.payload,
      };
    case 'GET_MY_OFFLINE_TIME_REQUESTS':
      return {
        ...state,
        myOfflineTimeRequests: action.payload,
      };
    case 'GET_IT_PERSONS':
      return {
        ...state,
        ITPersons: action.payload,
      };
    default:
      return state;
  }
};

  export default offlineTimeRequestsReducer;