import { GET_USER_PERMISSIONS } from 'actions/Types';

const initialState = {
  user_cv_detail: [],
  status: 0,
};

const cvReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'POST_CV_DETAILS':
      return {
        ...state,
        user_cv_detail: action.payload,
        status: action.status,
      };
    case 'CV_DETAILS_FAIL':
      return {
        ...state,
        user_cv_detail: [],
      };
    default:
      return { ...state };
  }
};
export default cvReducer;
