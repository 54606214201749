const initialState = {
  resume: {
    profile: {
      name: '',
      email: '',
      designation: '',
      experience: '',
    },
    summary: '',
    skills: [],
    projects: [],
    education: [],
  },
};

const resumeBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'summaryAdded':
      state.resume.summary = action.payload;

      return { ...state };

    case 'skillAdded':
      state.resume.skills.push(action.payload);

      return { ...state };

    case 'skillAdded':
      state.resume.skills = state.resume.skills.filter((skill) => skill.id !== action.payload);

      return { ...state };

    case 'projectAdded':
      state.resume.projects.push(action.payload);

      return { ...state };

    case 'projectRemoved':
      state.resume.skills = state.resume.projects.filter((project) => project.id !== action.payload);

      return { ...state };

    default:
      return { ...state };
  }
};

export default resumeBuilderReducer;
