const initialState = {
  tools: [],
  showLoader: false,
  text: '',
  filterText: ''
};

const toolsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CREATE_TOOL':
      return {
        ...state,
        text: '',
      }
    case 'TOOL_INPUT':
      return {
        ...state,
        text: action.value
      }

    case 'GET_TOOLS':
      return {
        ...state,
        tools: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export default toolsReducer;
