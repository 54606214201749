const initialState = {
  forms: [],
  array: [],
  reports: [],
  showLoader: false,
};

const monthlyAuditForm = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_INVOHUB_FORM':
      return {
        ...state,
        forms: action.payload,
        array: action.array,
      };
      case 'GET_AUDIT_DETAILS':
      return {
        ...state,
        reports: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
export default monthlyAuditForm;
