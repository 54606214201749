import { REMOVE_PROJECT, ADD_EMPLOYEE, ADD_PROJECTS } from 'actions/Types';

const initialState = {
  eyeModal: false,
  employee: {},
  projects: [],
};

const currentEmployeeDataReducer = (state = initialState, action) => {
  //Add Employee
  if (action.type === ADD_EMPLOYEE) {
    return {
      ...state,
      employee: action.payload,
    };
  }
  // Add project

  if (action.type === ADD_PROJECTS) {
    return {
      ...state,
      projects: action.payload,
    };
  }
  //Remove Project
  if (action.type === REMOVE_PROJECT) {
    return {
      ...state,
      employee: state.usersList?.find((el) => el.id === action.payload.userId),
      projects: state.usersList?.find((el) => el.id === action.payload.userId).employee.Projects?.filter((el) => el.id !== action.payload.projectId),
    };
  } else {
    return { ...state };
  }
};
export default currentEmployeeDataReducer;
