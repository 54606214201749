export const designations = (state, action) => {
    switch (action.type) {
      case 'GET_DESIGNATIONS':
        return {
          ...state,
          data: action.payload,
        };
      case 'LOADING_ACTION':
        return {
          ...state,
          showLoader: action.payload,
        };
      default:
        return { ...state };
    }
  };