const initialState = {
  graphDetails: [],
  graphEmpPerformance: [],
  showLoader: false,
};

const graphsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GRAPH_DETAILS':
      return {
        ...state,
        graphDetails: action.payload,
      };
    case 'GET_PERFORMANCE_GRAPH_DETAILS':
      return {
        ...state,
        graphEmpPerformance: action.payload,
      };
    case 'PERFORMANCE_GRAPH_FAIL':
      return {
        ...state,
        graphEmpPerformance: [],
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
export default graphsReducer;
