export const evaluations = (state, action) => {
  switch (action.type) {
    case 'GET_EVALUATIONS':
      return {
        ...state,
        data: action.payload,
      };
      case 'GET_HOME_EVALUATIONS':
        return {
          ...state,
          data: action.payload,
        };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const processUpdate = (state, action) => {
  switch (action.type) {
    case 'COMPLETE_PROCESS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const myComponent = (state, action) => {
  switch (action.type) {
    case 'GET_MY_COMPONENT':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getReviews = (state, action) => {
  switch (action.type) {
    case 'GET_REVIEWS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
