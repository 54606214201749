import { SECONDARY_PROJ_FLAG, USER_PERMISSION_MODAL, REMARKS_MODAL_FLAG, PM_DETAILS_MODAL } from 'actions/Types';

const initialState = {
  secondaryProjFlag: false,
  userPermissionModal: false,
};

const flagReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECONDARY_PROJ_FLAG:
      return {
        ...state,
        secondaryProjFlag: action.payload,
      };
    default:
      return { ...state };
  }
};

const userPermissionModal = (state = { userPermissionModal: false }, action) => {
  switch (action.type) {
    case USER_PERMISSION_MODAL:
      return {
        userPermissionModal: action.payload,
      };
    default:
      return state;
  }
};

const remarksModalReducer = (state = { remarksModal: { modal: false, remarksData: {} } }, action) => {
  switch (action.type) {
    case REMARKS_MODAL_FLAG:
      return {
        remarksModal: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

const PMDetailsModalReducer = (state = { pmDetailsModal: { modal: false, detailsData: {} } }, action) => {
  switch (action.type) {
    case PM_DETAILS_MODAL:
      return {
        pmDetailsModal: { ...action.payload },
      };
    default:
      return { ...state };
  }
};

export { flagReducer, userPermissionModal, remarksModalReducer, PMDetailsModalReducer };
