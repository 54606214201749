import { ALL_EMPLOYEES_PERMISSIONS, EMPLOYEE_PERMISSION_INFO } from 'actions/Types';
import { DEFAULT_PERMISSIONS } from '../../constants/common';

const employeeWithPermissions = (state = { employees: [], userPermissions: {}, showLoader: false }, action) => {
  switch (action.type) {
    case ALL_EMPLOYEES_PERMISSIONS:
      return {
        employees: action.payload,
        userPermissions: action.userPermissions,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return state;
  }
};

const employeePermissionInfo = (
  state = {
    employeePermissionInfo: {},
    showLoader: false,
  },
  action,
) => {
  switch (action.type) {
    case EMPLOYEE_PERMISSION_INFO:
      const employeePermissionInfo = action.payload;
      if (action.payload?.EmployeePermission === null) {
        const EmployeePermission = {};
        EmployeePermission.permissions = DEFAULT_PERMISSIONS.permissions;
        employeePermissionInfo.EmployeePermission = EmployeePermission;
      }

      employeePermissionInfo.EmployeePermission.permissions = Object.keys(employeePermissionInfo?.EmployeePermission?.permissions)
        ?.filter((key) => key !== 'board')
        ?.reduce((obj, key) => {
          obj[key] = employeePermissionInfo?.EmployeePermission.permissions[key];
          return obj;
        }, {});

      return { employeePermissionInfo };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return state;
  }
};

export { employeePermissionInfo, employeeWithPermissions };
