const initialState = {
  portfolio: [],
  array: [],
  showLoader: false,
};

const portfolioReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROJECT_PORTFOLIO':
      return {
        ...state,
        portfolio: action.payload,
        array: action.array,
      };
    case 'LOADING_ACTION':
      return {
        ...state,
        showLoader: action.payload,
      };
    default:
      return { ...state };
  }
};
export default portfolioReducer;
