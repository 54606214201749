const initialState = {
    data: [],
    showLoader: false,
  };
  
  const getSkillsRequest = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_SKILL_REQUEST':
        return {
          ...state,
          data: action.payload,
        };
      case 'LOADING_ACTION':
        return {
          ...state,
          showLoader: action.payload,
        };
      default:
        return { ...state };
    }
  };
  export default getSkillsRequest;