export const devColumns = 'DEV_COLUMNS';
export const qaColumns = 'QA_COLUMNS';

export const closeDevColumns = 'CLOSE_DEV_COLUMNS';
export const id = 'id';
export const name = 'name';
export const Department = 'Department';
export const Skills = 'Skills';
export const Exp = 'Exp';
export const Location = 'Location';
export const projectManager = 'projectManager';
export const Availability = 'Availability';
export const sinceWhen = 'sinceWhen';
export const Designation = 'Designation';
export const jobStatus = 'jobStatus';
export const Resume = 'Resume';
