import {
  GET_USER_PERMISSIONS,
  ADD_PM_IN_DIV,
  REV_PM_FROM_DIV,
  PROJECTS_TABLE_ERROR,
  CLEAR_PROJECTS_TABLE_ERROR,
  LOADING_ACTION,
} from 'actions/Types';
import { RESET_PROJECT_FILTERS, SET_PROJECT_FILTER } from '../actions/Types';

const filtersObj = {
  projectName: [],
  managerName: [],
  division: [],
  status: [],
  selectedTeam: [],
  selectedSaleTeamMember: [],
  selectedContract: [],
  selectedCountry: [],
  searchByBillingChannel: []
}


const initialState = {
  allDivisions: [],
  projects: [],
  available_employees: [],
  billing_employees: [],
  roles: [],
  project_managers: [],
  all_project_managers: [],
  user_detail: [],
  userPermissions: {},
  csm_employees: [],
  non_tech_employees: [],
  current_user: '',
  addPmInDiv: [],
  remPmFromDiv: [],
  showLoader: false,
  projectsWithEmployees: [],
  projectTimeline: [],
  slackChannels: [],
  getProjectDetails: {},
  updateProjectStatus: {},
  onboardingSuccess: '',
  filters: filtersObj,
};

const errorState = {
  isError: false,
  response: {},
};



const divisionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DIVISIONS':
      return {
        ...state,
        allDivisions: action.payload,
      };
    case 'GET_PROJECTS':
      return {
        ...state,
        projects: action.payload.projects,
        available_employees: action.payload.available_employees ? action.payload.available_employees : [],
        non_tech_employees: action.payload.non_tech_employees ? action.payload.non_tech_employees : [],
        roles: action.payload.roles,
        project_managers: action.payload?.project_managers ? action.payload?.project_managers : [],
        sales_teams: action.payload?.sales_teams ? action.payload.sales_teams : [],
        csm_employees: action.payload.csm_employees ? action.payload.csm_employees : [],
        allDivisions: action.payload.divisions ? action.payload.divisions : [],
        billing_employees: action.payload.billing_employees || [],
      };
    case 'ONBOARDING_SUCCESS':
      return {
        ...state,
        onboardingSuccess: action.payload,
      };
    case 'SET_PROJECT_WITH_EMPLOYEES':
      return { ...state, projectsWithEmployees: action.payload };
    case 'GET_SLACK_CHANNELS':
      return { ...state, slackChannels: action.payload.data || [] };
    case GET_USER_PERMISSIONS:
      return { ...state, userPermissions: action.payload };
    case 'TEAMS_FAIL':
      return {
        ...state,
        allDivisions: [],
      };
    case 'PROJECTS_FAIL':
      return {
        ...state,
        projects: [],
        available_employees: [],
        roles: [],
      };
    case 'GET_USER_DETAIL':
      return {
        ...state,
        user_detail: action.payload.Projects,
        current_user: action.payload.department,
        // projects: action.userProjects,
        project_managers: action.projectManagers,
        roles: action.userRoles,
        available_employees: action.availableEmployees,
        non_tech_employees: action.nonTechEmployees,
        sales_teams: action.sales_teams ? action.sales_teams : [],
      };
    case LOADING_ACTION:
      return { ...state, showLoader: action.payload };
    case ADD_PM_IN_DIV:
      return {
        ...state,
        user_detail: action.payload.Projects,
        current_user: action.payload.department,
        projects: action.userProjects,
        roles: action.userRoles,
        available_employees: action.availableEmployees,
        addPmInDiv: action.payload,
      };
    case REV_PM_FROM_DIV:
      return {
        ...state,
        user_detail: action.payload.Projects,
        current_user: action.payload.department,
        projects: action.userProjects,
        roles: action.userRoles,
        available_employees: action.availableEmployees,
        remPmFromDiv: action.payload,
      };
    case 'GET_ALL_PM':
      return {
        all_project_managers: action.payload,
      };
    case 'SET_PROJECT_TIMELINE':
      return {
        ...state,
        projectTimeline: action.payload[0]?.data,
      };
    case 'SET_PROJECT_DETAIL':
      return {
        ...state,
        getProjectDetails: action.payload.project,
      };
    case 'UPDATE_PROJECT_STATUS':
      return {
        ...state,
        updateProjectStatus: action.payload,
      }
    case SET_PROJECT_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value
            ? action.payload.value.map((item) => ({
                ...item,
                name: action.payload.name,
              }))
            : [],
        },
      };
    case RESET_PROJECT_FILTERS:
      return {
        ...state,
        filters: filtersObj,
      };
    default:
      return { ...state };
  }
};

const projectsErrorReducer = (state = errorState, action) => {
  switch (action.type) {
    case PROJECTS_TABLE_ERROR:
      state = {
        isError: true,
        response: action.payload,
      };
      return state;
    case CLEAR_PROJECTS_TABLE_ERROR:
      state = {
        isError: false,
        response: {},
      };
      return state;
    default:
      return state;
  }
};


export { divisionsReducer, projectsErrorReducer };
