export const webhookReducers = (state, action) => {
    switch (action.type) {
      case 'GET_WEBHOOKS':
        return {
          ...state,
          webhooks: action.payload,
        };
        
      case 'GET_WEBHOOK_LOGS':
        return {
          ...state,
          webhookLogs: action.payload,
        };
      
      case 'LOADING_ACTION':
        return {
          ...state,
          showLoader: action.payload,
        };
      default:
        return { ...state };
    }
  };